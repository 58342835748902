.App {
  text-align: center;
  height: 100vh;
  width: 100%;
}
body{
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

/* rotation */
/* 
#loading{
    width: 50px;
    animation: rotation 2s infinite linear;
  
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */

/* breathing */
#loading{
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
  }


@-webkit-keyframes breathing {
0% {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

25% {
  -webkit-transform: scale(1);
  transform: scale(1);
}

60% {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

100% {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
}

@keyframes breathing {
0% {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

25% {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

60% {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

100% {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
}

button[title="Stop drawing"] {
  height: 38px;
  width: 38px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button[title="Draw a shape"] {
  height: 38px;
  width: 38px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button[title="Draw a line"] {
  height: 38px;
  width: 38px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}