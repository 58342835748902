.scrolling::-webkit-scrollbar{
    display: none;
}

.card_container{
    /* box-shadow: 0px 0px 66px 1px rgb(168, 168, 168); */
    backdrop-filter: blur(30px) saturate(2);
    -webkit-backdrop-filter: blur(30px) saturate(2);
    transition: 0.4s cubic-bezier(.77,-0.01,0,.77);
    overflow-y: auto;
    overflow-x: hidden;
}

.card_container.closed {
    margin-left: -900px !important;
}

.accordion {
    background-color: #2b2b2b00;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .accordion:hover {
    background-color: #2b2b2b00;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
  }