.action .profile {
    position: relative;
    width: 35px;
    height: 35px;
    margin-left: 20px;
    border-radius: 50%;
    background-color: white;
    margin-top: 5px;
    overflow: hidden;
    cursor: pointer;
}

.action .profile img {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.action .menu {
    position: absolute;
    top: 90px;
    right: 10px;
    padding: 10px 20px;
    background: #fff;
    width: 200px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}

.action .menu.active {
    top: 80px;
    visibility: visible;
    opacity: 1;
}

.action .menu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 28px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
}

.action .menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    /* padding: 20px 0; */
    font-weight: 500;
    color: #555;
    line-height: 1.5em;
}

.action .menu h3 span {
    font-size: 14px;
    color: #cecece;
    font-weight: 300;
}

.action .menu ul li {
    list-style: none;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
}

.action .menu ul li img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
}

.action .menu ul li:hover img {
    opacity: 1;
}

.action .menu ul li a {
    display: inline-block;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
}

.action .menu ul li:hover a {
    color: #ff5d94;
}

#search-input {
    background-color: white;
    color: black;
}

#search-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}