.login_container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.login_main_container{
    /* display: grid; */
    /* background-color: lightblue; */
    /* grid-template-columns: .5fr 1fr; */
    display: flex;
    /* position: absolute; */
    height: 65%;
    width: 57%;
    top: 15%;
    left: 25;
    margin-top: 10.5%;
    gap:7%;
    background: rgba( 0, 0, 0, 0.3 );
    box-shadow: 0 0px 0px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    }

.video_container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(102, 127, 193);
}

.video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #0404047c;
}

.login_vertical_line{

    margin-top: auto;
    margin-bottom: auto;
    height: 250px;
    width: 1px;
    background-color: rgba(128, 128, 128, 0.349);
}
.login_image_container {
    display: flex;
    flex-wrap: wrap;
    /* position: absolute; */
    justify-content: start;
    align-items: center;
    margin:auto -43px auto 40px;
    /* top: 30%;
    left: 25%;
    color: white;
    font-size: 3rem;
    width: 30%;
    font-family: source-sans-pro;
    font-weight: 600;
    z-index: 1; */
}

@media only screen and (min-width: 940px) {
    .product-tagline {
        top: 20%
    }
}

.login_video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.login_form_container {
    /* position: absolute;
    left: 60%;
    top: 10%; */
    /* width: 22%; */
    margin-top: 3%;
    margin-bottom: 2%;
}

input[type="checkbox"] {
    accent-color: rgb(230, 232, 240) !important;
}

#outlined-basic-label{
    color: white;
}

.phiscan-logo {
    height: 65px;
    width: 65px;
}

#login-btn:hover {
    background-color: rgb(19 37 85) !important;
    color: white !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    color: white !important;
}